.smalltxt {
  color: #171c3f;
  line-height: 30px;
  font-family: "tt_commonsmedium", sans-serif;
}

@media only screen and (min-width: 1439px) and (max-width: 2560px) {
  .smalltxt {
    font-size: 24px;
    font-weight: 400;
    line-height: 45px;
    text-align: initial;
    margin-bottom: 50px;
  }

}

/* .........media query..... */
@media only screen and (min-width: 424px) and (max-width: 767px) {
  .smalltxt {
    font-size: 20px;
    line-height: 30px;
    text-align: initial;
    margin-bottom: 50px;

  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .smalltxt {
    text-align: initial;
    font-size: 20px;
    line-height: 34px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 993px) {
  .smalltxt {
    font-size: 18px;
    line-height: 30px;
    text-align: initial;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 319px) and (max-width:375) {
  .smalltxt {
    font-size: 18px;
    line-height: 30px;
    text-align: initial;
    margin-bottom: 50px;

  }
}

@media screen and (max-width: 980px) {
  .smalltxt {
    font-size: 16px;
    line-height: 30px;
    text-align: initial;
    margin-bottom: 50px;
  }
}
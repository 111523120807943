.resume-row {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 65px;
  margin-bottom: 65px;
}

.head {
  font-size: 50px;
  color: #171c3f;
  display: inline-block;
}

.image {
  width: 80%;
}

input[type="checkbox"] :checked ::after {
  color: #171c3f;
}

.form {
  width: 80%;
  margin: auto;
  height: auto;
  padding: 1rem;
  border-top: 1px;
  border-left: 0px;
  box-shadow: none;
  margin-bottom: 5rem;
  border-radius: 10px;
  border-right-width: 9px;
  border-color: #00d6fb40;
  border-radius: 10px;
  font-size: 16px;
  border-bottom-width: 9px;
  font-family: "noto_sansregular", sans-serif;
  box-shadow: -8px 0px 7px 6px rgb(0 0 0 /8%);
}

.jobdes-title {
  font-size: 22px;
  font-weight: 600;
  line-height: 33px;
  border-bottom: 1px solid #d7d7d7;
  background: #fff;
  margin-top: 10px;
  text-align: left;
  color: #171c3f;
  font-family: "tt_commonsdemibold", sans-serif;
}

.jobdes-text {
  text-align: left;
  font-size: 16px;
  line-height: 25px;
  color: #6a6a6a;
  text-align: initial;
  font-weight: 400;
  font-family: "tt_commonsmedium", sans-serif;
}

@media screen and (max-width: 930px) {
  .form {
    width: 70%;
    margin: none;
  }

  .custom-input {
    padding: rem;
    margin-top: 3rem;
    margin-top: 1rem;
    border-top: none;
    box-sizing: none;
    color: #171c3f;
    border-left: none;
    border-right: none;
    border-radius: none;
    align-self: flex-start;
    border-bottom-width: 2px;
  }

  .head {
    font-size: 50px;
    text-align: center;
    display: inline-block;
  }

}

@media screen and (max-width: 640px) {
  .form {
    width: 70%;
    margin: none;
  }

  .custom-input {
    padding: rem;
    margin-top: 3rem;
    margin-top: 1rem;
    border-top: none;
    border-left: none;
    box-sizing: none;
    color: #171c3f;
    border-right: none;
    border-radius: none;
    align-self: flex-start;
    border-bottom-width: 2px;
  }

  .head {
    font-size: 30px;
    text-align: center;
    display: inline-block;
  }
}

@media screen and (max-width: 480px) {
  .form {
    width: 100%;
    margin: none;
  }

  .custom-input {
    padding: rem;
    margin-top: 3rem;
    margin-top: 1rem;
    border-top: none;
    color: #171c3f;
    box-sizing: none;
    border-left: none;
    border-right: none;
    border-radius: none;
    align-self: flex-start;
    border-bottom-width: 2px;
  }

  .head {
    font-size: 20px;
    text-align: center;
    display: inline-flex;
  }
}

@media screen and (max-width: 320px) {
  .form {
    width: 100%;
    margin: none;
  }

  .custom-input {
    padding: rem;
    margin-top: 3rem;
    margin-top: 1rem;
    border-top: none;
    border-left: none;
    color: #171c3f;
    box-sizing: none;
    border-right: none;
    border-radius: none;
    border-bottom-width: 2px;
    align-self: flex-start;
  }

  .head {
    font-size: 20px;
    text-align: center;
    display: inline-flex;
  }
}
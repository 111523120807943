.recruitment-card {
  width: auto;
  border: none;
  min-height: 270px;
  letter-spacing: -0.5px;
  background-color: #fff;
  padding: 34px 20px 34px;
  border-top: 10px solid #00d6fb;
  border-radius: 6px 6px 0 0;
  transition: all 0.5s ease 0s;
  box-shadow: 0 -2px 20px rgb(0 0 0 / 12%);
}

.recruitment-card-text {
  font-size: 26px;
  line-height: 42px;
  font-weight: 700;
  text-align: center;
  font-family: "tt_commonsdemibold", sans-serif;
}

.recruitment-card-detail {
  text-align: center;
  font-size: 18px;
  color: #6a6a6a;
  line-height: 36px;
  font-weight: 400;
}
.content {
  text-align: start;
  font-size: 24px;
  line-height: 33px;
  margin-bottom: 3px;
  font-weight: 600;
  color: #171c3f;
  font-family: 'tt_commonsmedium', sans-serif;
}

.photo {
  border-radius: 100%;
}

.feedback {
  top: 4398px;
  font-weight: 500;
  line-height: 40px;
  color: #171c3f;
  font-family: 'tt_commonsmedium', sans-serif;
}

.invert {
  width: 62px;
  height: 34px;
}

.feedback-bg1 {
  background-color: #ff017d57;
}

.feedback-bg2 {
  background-color: #00d6fb57;
}

.designation {
  text-align: start;
  color: #6a6a6a;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3px;
  font-family: 'noto_sansregular', sans-serif;
}

@media only screen and (min-width:320px) and (max-width:425px) {
  .photo {
    width: 100px;
    height: 100px;
  }

  .content {
    font-size: 16px;
  }

  .feedback {
    font-size: 16px;
  }
}

@media only screen and (min-width:768px) {
  .photo {
    width: 50px;
    height: 50px;
    padding-right: 2px;
  }

  .content {
    margin-left: 10px;
    font-size: 18px;
  }

  .designation {
    margin-left: 10px;
  }
}
.man {
  position: relative;
  font-size: 24px;
  color: #171c3f;
  line-height: normal;
  letter-spacing: -0.02em;
  font-family: 'tt_commonsdemibold', sans-serif;

  @media only screen and (max-width: 500px) {
    font-size: 2px;
  }
}

.nav-link{
  padding: 4px;
}
.man .nlink {
  width: fit-content;
  font-size: 16px;
  color: #171c3f;
  margin-right: 25px;
  position: relative;
}

.con {
  color: #171c3f;
  display: inline;
  letter-spacing: -0.01em;
}

.con .gap {
  margin-left: 30px;
}

.line {
  padding: 1px;
  width: 30px;
  color: #6a6a6a;
  transform: rotate(90deg);
}

.icon {
  border-radius: 20px;
  transition: all .8s;
}

.icon:hover {
  transition: all .8s;
}

.icon-g {
  width: 33px;
  height: 33px;
  color: #171c3f;
  background-color: #ffffff;
}

.icon-g:hover {
  color: #6e5494;
}

.icon-f {
  width: 33px;
  height: 33px;
  color: #171c3f;
  background-color: #ffffff;
}

.icon-f:hover {
  color: #3c5a9a;
}

.icon-i {
  width: 35px;
  height: 35px;
  color: #171c3f;
  background-color: #ffffff;
}

.icon-i:hover {
  color: #ff017d;
}

.icon-t {
  width: 35px;
  height: 35px;
  color: #171c3f;
  background-color: #ffffff;
}

.icon-t:hover {
  color: #2daae1;
}

.icon-li {
  width: 33px;
  height: 33px;
  color: #ffffff;
  background-color: #171c3f;
}

.icon-li:hover {
  background-color: #007ab9;
}

.map-icon {
  width: 20px;
  cursor: pointer;
  margin-left: -208px;
  margin-top: -85px;
}

.link {
  text-decoration: none;
  color: #171c3f;
}

.map {
  font-size: 10px;
}

.cardgap {
  margin-left: 12px;
}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 5s linear infinite;
}

.footer-Res {
  width: 100%;
  height: fit-content;
  position: relative;
}

.hover-icon-fb {
  color: #fff;
}

.hover-icon-fb:after {
  color-scheme: initial;
  color: #00d6fb;
}

.hover-icon-fb:hover:after {
  color: #00d6fb;
  transform-origin: center;
}

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}

.showCard {
  width: 249px;
  height: 110px;
  margin-left: 155px;
  margin-top: -80px;
}

.copy {
  width: 100%;
  font-size: 14px;
  text-align: center;
  color: #171c3f;
  background-color: rgba(0, 0, 0, 0.2);
  font-family: 'noto_sansregular', sans-serif;
}

.underline {
  width: 6rem;
  color: #00d6fb;
  border: 2px solid;
}
.opening-header {
    color: #171c3f;
}

.openingText {
    font-size: 23px;
    color: #ff017d;
    line-height: 38px;
}

.openingHead {
    font-size: 23px;
    color: #ffbc00;
}
.custom-input {
  margin-top: 2rem;
  border-top: none;
  border-left: none;
  color: #171c3f;
  box-sizing: none;
  border-right: none;
  border-radius: none;
  border-bottom-width: 2px;
}

.custom-input:focus {
  box-shadow: none;
  border-bottom-color: #00d6fb;
}

.err-text {
  font-size: 12px;
  font-weight: 600;
  color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}

.uploadLabel {
  align: start;
  display: inline-block;
  margin-right: 120px;
  margin-top: 30px;
}

.image-upload>input {
  display: none;
  margin-left: 100px;
}

.image-upload img {
  width: 80px;
  cursor: pointer;
}

.check:focus {
  box-shadow: none;
  border: none;
  background-color: #00d6fb;
}
.h-card {
  overflow: hidden;
  max-width: 300px;
  height: 400px;
  padding: 15px;
  border: none;
  background-color: #FFFFFF;
  transition: all 0.5s ease 0s;
  box-shadow: 0 -2px 20px rgb(0 0 0 / 12%);
}

.rectangle-img {
  width: 70px;
  transition: all 0.5s ease-in-out;
}

.rectangle-img:hover {
  @include transition2;
  transition: all 0.5s ease 0s;
  -webkit-transform: scale(1.2);
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.rectangle-title {
  font-size: 24px;
  line-height: 42px;
  font-weight: 700;
  line-height: 33px;
  text-align: left;
  font-family: 'tt_commonsdemibold', sans-serif;
}

.rectangle-text {
  text-align: left;
  font-size: 18px;
  color: #171c3f;
  line-height: 36px;
  font-family: 'noto_sansregular', sans-serif;
}

.card-first-col1 {
  text-align: start;
  margin: auto;
}
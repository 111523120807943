.blog-c {
    display: flex;
    justify-content: center;
}

.blog-color {
    text-align: left;
    font-size: 18px;
    color: #171c3f;
    line-height: 36px;
    font-family: 'noto_sansregular', sans-serif;
}

.blog-card {
    height: fit-content;
    border: none;
    width: 750px;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}

.blog-header {
    text-align: start;
    border: none;
    background: none;
}

.blog-content {
    /* text-align: start; */
    color: #6a6a6a;
    font-family: 'noto_sansregular', sans-serif;
}

.blog-body {
    background-color: #FFBC0080;
}

.blog-text {
    color: #171c3f;
    font-size: 45px;
    font-weight: 600;
    line-height: 50px;
    text-align: center;
    letter-spacing: -0.02em;
    font-family: "tt_commonsdemibold", sans-serif;
}

.blog-heading {
    font-size: 30px;
    font-family: "tt_commonsdemibold", sans-serif;

}

.blog-smtext {
    color: #6a6a6a;
    font-size: 24px;
    font-weight: 400;
    line-height: 40px;
    text-align: initial;
    font-family: 'noto_sansregular', sans-serif;
}

.blog-i {
    display: flex;
    justify-content: flex-end;
    background-color: #171c3f;
    color: white;
}

.b-b {
    width: fit-content;
    background-color: #171c3f;
    border: none;
    color: white;
}

.blog-img {
    width: 100%;
}
.case-c {
    display: flex;
    justify-content: center;
}

.case-card {
    height: fit-content;
    width: 750px;
    box-shadow: none;
    border: none;
    z-index: 2;
}

.case-img {
    filter: blur(3px);
}

.subHeading {
    font-size: 30px;
    font-weight: 600;
    letter-spacing: -.02em;
    text-align: left;
    color: #171c3f;
    font-family: tt_commonsdemibold, sans-serif;
}

.case-logo {
    width: 30%;
    margin-top: 60px;
}

.in-card {
    z-index: 5;
    width: 60%;
    margin-top: -80px;
    border: none;
    height: fit-content;
    background-color: #FFBC0080;
}

.case-title {
    text-align: start;
    font-size: 28px;
    max-width: 400px;
    line-height: 40px;
    font-weight: 600;
}

.c-b {
    border: none;
    color: #fff;
    width: fit-content;
    height: fit-content;
    background-color: #171c3f;
}

.case-b {
    text-align: end;
}

.case-img {
    width: 100%;
}

.case-heading {
    text-align: center;
}

.case-text {
    text-align: start;
    color: #6a6a6a;
    font-size: 20px;
    font-family: tt_commonsmedium, sans-serif;
    line-height: 35.9px;
}

.case-header {
    font-size: 50px;
    text-align: center;
    margin-bottom: 21px;
    font-family: tt_commonsdemibold, sans-serif;
    line-height: 70px;
    font-weight: 600;
    letter-spacing: -1.6px;
}
.aboutText {
  font-size: 20px;
  line-height: 36px;
  text-align: initial;
  font-weight: 400;
  margin-bottom: 15px;
  color: #171c3f;
  font-family: "tt_commonsmedium", sans-serif;
}

.our {
  font-size: 45px;
  color: #171c3f;
  line-height: 58px;
}

.ourdetail {
  font-size: 22px;
  color: #171c3f;
  line-height: 27px;
  text-align: center;
}

.aboutContent {
  line-height: 1200px;
  margin-top: 200px;
}
.oops {
    font-family: "tt_commonssmall", sans-serif;

}

.data {
    font-family: "tt_commonssmall", sans-serif;

}

.server {
    border: none;
    box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
}
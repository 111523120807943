.count {
  color: #171c3f;
  font-size: 40px;
  line-height: 54px;
  text-align: center;
  font-weight: 700;
  font-family: 'tt_commonsdemibold', sans-serif;
}

.detail {
  font-size: 20px;
  line-height: 27px;
  color: #171c3f;
  text-align: center;
  font-family: 'tt_commonsmedium', sans-serif;
}
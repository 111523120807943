.bannerHeader {
  font-size: 50px;
  color: #171c3f;
  line-height: 50px;
  margin-bottom: 2rem;
  font-weight: 600;
  text-align: center;
  font-family: "tt_commonsdemibold", sans-serif;
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .bannerHeader {
    font-size: 32px;
    color: #171c3f;
    line-height: 50px;
    margin-bottom: 50px;
    font-weight: 600;
    text-align: center;
    font-family: "tt_commonsdemibold", sans-serif;
  }
}

@media only screen and (min-width: 319px) and (max-width: 375px) {
  .bannerHeader {
    font-size: 30px;
    margin-bottom: 35px;
  }
}
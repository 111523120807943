.bg {
  font-size: 25px;
  color: #171c3f;
  font-size: 32px;
  margin-bottom: 30px;
  font-weight: 600;
  margin-top: 0;
  line-height: 30px;
  font-family: 'tt_commonsdemibold', sans-serif;
}

.details {
  font-family: "tt_commonsdemibold", sans-serif;
  font-size: 16px;
  color: #171c3f;
  font-weight: 600;
}

.p-btn {
  color: #171c3f;
  border-radius: 0px;
  border-bottom: 1px dashed #6a6a6a;
}

.p-btn:focus {
  outline: 0px;
  color: #171c3f;
  box-shadow: none;
  background-color: #ffffff;
  border-bottom: 3px dashed #00d6fb;
}

.data-start {
  text-align: start;
}

.data-end {
  text-align: end;
}

.apply-btn {
  color: #171c3f;
  font-size: 16px;
  font-weight: 600;
  z-index: 1;
  margin: 0 auto;
  position: relative;
  line-height: inherit;
  transition: all 0.9s ease;
  border-bottom: 2px solid #00d6fb;
}

.apply-btn:hover {
  color: #00d6fb;
  transition: all 0.5s ease-in-out;
}

.position {
  text-align: left;
  font-size: 18px;
  color: #171c3f;
  line-height: 36px;
  font-family: 'noto_sansregular', sans-serif;
}
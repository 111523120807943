.banner {
  border: none;
}

.banner-div {
  position: relative;
}

.banner-card {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.bannerInnerText {
  color: #171c3f;
  font-size: 24px;
  font-weight: 500;
  line-height: 50px;
  margin-bottom: 80px;
  text-align: initial;
  max-width: 900px;
  font-family: "tt_commonsmedium", sans-serif;
}

.link:hover {
  color: #00d6fb;
}

.creative:hover {
  color: #FFBC00;
}

/*--------------- media Query------------------------------------- */

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .bannerInnerText {
    font-size: 18px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 993px) {
  .bannerInnerText {
    font-size: 18px;
    line-height: 35px;
  }
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .bannerInnerText {
    font-size: 15px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 374px) and (max-width: 425px) {
  .bannerInnerText {
    font-size: 14px;
    line-height: 32px;
  }
}

@media only screen and (min-width: 319px) and (max-width:375) {

  .bannerInnerText {
    font-size: 14px;
  }
}

@media only screen and (max-width:336) {
  .bannerInnerText {
    font-size: 12px;
    line-height: 30px;
  }
}

@media only screen and (max-width: 320px) {
  .bannerInnerText {
    font-size: 12px;
    line-height: 30px;
  }
}
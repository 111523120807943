.nav-p {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  margin-top: 40px;
  background-color: #fff;
}

.manlink {
  display: inline-flex;
  align-items: center;
}

a,
a:hover,
a:focus,
a:active {
  font-weight: 600;
  color: #171C3F;
  text-decoration: none;
  font-family: "noto_sanssemibold", sans-serif;
}

.manlink .nlink {
  margin-left: 30px;
  display: inline;
}

.navlink {
  font-family: "noto_sanssemibold", sans-serif;
  padding-bottom: 3px;
  color: #171C3F;
  line-height: 30px;
  position: relative;
  border-bottom: 3.5px solid #00D6FB;
}

.manlink .mybtn {
  margin-left: 30px;
  margin-bottom: 6px;
}

.hover-underline-animation {
  line-height: 30px;
  position: relative;
  color: #171C3F;
  display: inline-block;
}

.hover-underline-animation:after {
  left: 0;
  bottom: 0;
  content: "";
  width: 100%;
  height: 3px;
  position: absolute;
  transform: scaleX(0);
  transform-origin: center;
  background-color: #00D6FB;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  color: #171C3F;
  transform-origin: center;
  border-bottom: 3.5px solid #00D6FB;
}
.header {
  color: #171c3f;
  font-size: 50px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  letter-spacing: -0.02em;
  font-family: "tt_commonsdemibold", sans-serif;
}

@media only screen and (min-width: 1439px) and (max-width: 2560px) {
  .header {
    color: #171c3f;
    font-size: 45px;
    font-weight: 600;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    font-family: "tt_commonsdemibold", sans-serif;
  }
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .header {
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    letter-spacing: -0.02em;
    font-family: "tt_commonsdemibold", sans-serif;
  }
}

@media only screen and (min-width: 319px) and (max-width: 375px) {
  .header {
    line-height: 35px;
  }
}
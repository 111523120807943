.address {
  border: none;
  box-shadow: none;
  object-fit: cover;
  height: fit-content;
  position: relative;

  &--image {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &--info {
    --size: 7px;
    overflow: hidden;
    width: var(--size);
    height: var(--size);
    position: absolute;
    border-radius: 10px;
    background: #171c3f;
    border: 1px solid rgba(0, 0, 0, 0.125);
    transition: width 550ms ease, height 550ms ease, right 550ms ease,
      background 550ms ease;

    &::after {
      content: "";
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 0;
      height: 100%;
      width: 100%;
      position: absolute;
      transition: opacity 550ms ease;
      background: linear-gradient(to top,
          rgba(0, 0, 0, 0.65) 0%,
          rgba(0, 0, 0, 0.25) 50%,
          transparent 100%);
    }

    &.info--1 {
      bottom: 57%;
      right: 31%;

      .info--image {
        img {
          top: -100%;
          left: -20%;
        }
      }
    }

    &.info--2 {
      bottom: 57%;
      right: 31%;

      .info--image {
        img {
          right: -20%;
          bottom: -80%;
        }
      }
    }

    &.info--3 {
      bottom: 52%;
      right: 29%;

      .info--image {
        img {
          bottom: 52%;
          top: -80%;
        }
      }
    }

    &.info--4 {
      bottom: 60%;
      right: 33%;

      .info--image {
        img {
          bottom: 28%;
          top: -10%;
        }
      }
    }

    &.info--5 {
      bottom: 78%;
      right: 85%;

      .info--image {
        img {
          top: -50%;
          left: -10%;
        }
      }
    }

    .icon {
      left: 50%;
      top: 50%;
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);

      ion-icon {
        color: white;
      }
    }

    .info--text {
      left: 10%;
      bottom: 5%;
      z-index: 2;
      opacity: 0;
      color: #fff;
      display: block;
      position: absolute;
      font-size: 0.5rem;
      letter-spacing: 1px;
      white-space: nowrap;
      transform: translateY(25%);
      font-family: 'tt_commonsmedium', sans-serif;
      transition: opacity 250ms ease, transform 250ms ease;
    }

    .info--image {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: relative;

      img {
        opacity: 0;
        position: absolute;
        object-fit: cover;
        transition: opacity 550ms ease;
      }
    }
  }

  &:hover {
    .address--info {
      --size: 100px;

      &::after {
        opacity: 1;
      }

      &.info--1 {
        right: 51%;
        transition-delay: 100ms;
      }

      &.info--2 {
        right: 15.8%;
      }

      &.info--3 {
        --size: 120px;
        top: 45%;
      }

      &.info--4 {
        --size: 105px;
        top: 15%;
      }

      &.info--5 {
        --size: 100px;
        top: 15%;
      }

      .info--image {
        img {
          opacity: 1;
        }
      }

      .info--text {
        transition-delay: 550ms;
        opacity: 1;
        transform: translateY(0%);
        font-family: 'tt_commonsmedium', sans-serif;
      }
    }
  }

}

@media screen and (max-width: 980px) {
  .address {
    object-fit: cover;

    &--info {
      &.info--1 {
        bottom: 75%;
        right: 31%;

        .info--image {
          img {
            top: -80%;
            left: -20%;
          }
        }
      }

      &.info--2 {
        bottom: 75%;
        right: 31%;

        .info--image {
          img {
            right: -20%;
            bottom: -80%;
          }
        }
      }
      &.info--4 {
        top: 22%;
        bottom: 15%;
        left: 81.8%;
      }

      &.info--5 {
        bottom: 70%;
      }
    }
  }
}
.apply {
  text-align: left !important;
  background-color: #ffbc0050;
}

.heading {
  color: #ff017d;
  font-size: 32px;
  font-weight: 600;
  line-height: 42px;
  font-family: "tt_commonsdemibold", sans-serif;
}

.join {
  font-size: 18px;
  color: #171c3f;
  font-weight: 600;
  line-height: 36px;
}

.headingText {
  color: #00d6fb;
  font-size: 26px;
  margin-top: 30px;
  font-weight: 600;
  font-family: "tt_commonsdemibold", sans-serif;
}

.smalltext {
  color: #00d6fb;
  font-size: 20px;
  margin-top: -50px;
  margin-left: 800px;
  letter-spacing: -0.5px;
  font-family: "noto_sansregular", sans-serif;
}

.text-cnt {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textheading {
  border: none;
  line-height: 36px;
  font-weight: 500;
  text-align: start;
  border-left: 5px solid #00d6fb;
  font-family: "tt_commonsmedium", sans-serif;
}

.headingsmall {
  color: #ffbc00;
  font-family: "tt_commonsdemibold", sans-serif;
  font-weight: 600;
}

.small {
  color: #ff017d;
  font-size: 20px;
  letter-spacing: -0.5px;
  font-family: "tt_commonsdemibold", sans-serif;
}

.contact {
  margin: 20px;
  font-size: 20px;
  font-weight: 600;
  font-family: "noto_sansregular", sans-serif;
}

.hyperlink {
  color: #00d6fb;
}

.headerLife {
  color: #171c3f;
  font-size: 45px;
  font-weight: 600;
  line-height: 60px;
  text-align: center;
  letter-spacing: 0.08em;
  font-family: "tt_commonsdemibold", sans-serif;
}

.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

@media screen and (max-width: 1038px) {
  .header {
    font-size: 30px;
  }

  .headerLife {
    font-size: 14px;
    line-height: 50px;
    letter-spacing: 0.12em;
  }
}

@media screen and (max-width: 980px) {
  .headerLife {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.10em;
  }

  .smalltext {
    font-size: 14px;
    font-weight: 500;
    margin-left: 100px;
  }

  .headingsmall {
    margin-right: 0px;
    font-weight: 600;
  }
}

@media only screen and (min-width: 1439px) and (max-width: 2560px) {
  .smalltext {
    font-size: 20px;
    margin-top: -70px;
    font-weight: 500;
  }

  .small {
    font-size: 20px;
    letter-spacing: -0.5px;
  }

  .headerLife {
    font-size: 40px;
    line-height: 50px;
    letter-spacing: 0.10em;
  }
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .smalltext {
    font-size: 16px;
    margin-left: 200px;
    letter-spacing: -1px;
  }

  .small {
    font-size: 18px;
    letter-spacing: -0.5px;
  }

  .headerLife {
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.12em;
  }
}

@media only screen and (min-width: 319px) and (max-width: 375px) {
  .headerLife {
    font-size: 14px;
    line-height: 25px;
    letter-spacing: 0.10em;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .smalltext {
    font-size: 14px;
    margin-left: 600px;
    align-self: center;
    letter-spacing: 0.3px;
  }

  .headerLife {
    font-size: 22px;
    line-height: 40px;
    letter-spacing: 0.10em;
  }

  .headingsmall {
    margin-right: 0px;
    font-size: 20px;
  }

  .headingText {
    font-size: 18px;
    margin-left: 10px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 993px) {
  .smalltext {
    font-size: 14px;
    margin-left: 450px;
    line-height: 20px;
    letter-spacing: 0.01px;
    align-self: center;
  }

  .headerLife {
    font-size: 22px;
    line-height: 40px;
    letter-spacing: 0.10em;
  }
}
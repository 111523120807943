.passion-man-row {
  position: relative;
}

.passion-heading {
  text-align: left;
  color: #171C3F;
  margin-bottom: 30px;
  font-size: 45px;
  line-height: 60px;
  font-weight: 600;
  font-family: 'tt_commonsdemibold', sans-serif;
}

.passion-man-row .passion-pera1 {
  width: 80%;
  margin-left: 13px;
  border-radius: 15px;
  background-color: #FF017D;
}

.pera-content {
  text-align: left;
  font-size: 24px;
  line-height: 36px;
  color: #fff;
  font-family: 'tt_commonsmedium', sans-serif;
}

.pera-content-2 {
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  margin-top: 0;
  line-height: 36px;
  color: #171C3F;
}

.passion-pera-2 {
  width: 90%;
  padding-left: 20px;
}

.passion-img {
  height: auto;
  min-width: 240px;
}

.passion-btn {
  width: 40%;
}

.passion-btn-inner .passion-btn {
  border-radius: 10px;
}

/* ............media query....... */


@media only screen and (min-width: 374px) and (max-width: 425px) {
  .pera-content {
    font-size: 11px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .pera-content {
    font-size: 18px;
  }

  .passion-man-row .passion-pera1 {
    width: 95%;
    margin-left: 5px;
  }

  .passion-heading {
    margin-bottom: 10px;
    font-size: 35px;
    line-height: 50px;
  }

  .passion-pera-2 {
    width: 96%;
  }


  .pera-content-2 {
    font-size: 14px;
    line-height: 30px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 993px) {
  .pera-content {
    font-size: 18px;
  }

  .passion-man-row .passion-pera1 {
    width: 100%;
    margin-left: 1px;
  }

  .passion-heading {
    font-size: 30px;
    line-height: 30px;
  }

  .passion-pera-2 {
    width: 99%;
  }


  .pera-content-2 {
    font-size: 12px;
    line-height: 25px;
  }

  .passion-img {
    height: 350px;
    margin-top: 50px;
    max-width: 270px;
  }
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .passion-heading {
    font-size: 28px;
    line-height: 30px;
  }
}

@media screen and (max-width: 980px) {
  .pera-content {
    font-size: 14px;
    line-height: 20px;
  }

  .passion-heading {
    line-height: 50px;
    font-size: 35px;
  }

  .passion-img {
    min-width: 300px;
  }
}

@media screen and (max-width: 320px) {
  .passion-pera-2 {
    width: 120%;
    padding-left: 0px;
  }

  .passion-man-row .passion-pera1 {
    width: 105%;
    margin-left: 0px;

  }
}
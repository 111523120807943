.process-img-div {
  border: none;
  justify-content: center;
  margin: 0px auto;
  max-width: 800px;
}

@media only screen and (min-width: 992px) and (max-width: 1440px) {
  .process-img-div .img-text {
    font-size: 22px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 993px) {
  .process-img-div .img-text {
    font-size: 18px;
  }
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .process-img-div .img-text {
    font-size: 15px;
  }
}

@media only screen and (min-width: 374px) and (max-width: 425px) {
  .process-img-div .img-text {
    font-size: 11px;
  }
}

@media only screen and (min-width: 319px) and (max-width: 375px) {
  .process-img-div .img-text {
    font-size: 10px;
  }
}

@media only screen and (max-width: 320px) {
  .process-img-div .img-text {
    font-size: 9px;
  }
}

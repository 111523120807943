.button {
  outline: none;
  font-size: 16px;
  font-weight: 600;
  color: #171c3f;
  z-index: 1;
  margin: 0 auto;
  padding: 17px 28px 17px 28px;
  position: relative;
  transition: all 0.9s ease;
  line-height: inherit;
  border: 2px solid #00d6fb;
  justify-content: space-around;
  background-color: transparent;
}

.button:hover {
  background-color: #00d6fb;
  color: #171c3f;
}

/* ........media query */

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .button {
    font-size: 14px;
    padding: 8px 10px 8px 10px;
  }
}
.life-card {
  padding: 15px;
  overflow: hidden;
  height: 380px;
  border: none;
  max-width: 450px;
  background-color: #FFFFFF;
  transition: all 0.5s ease 0s;
  box-shadow: 0 -2px 20px rgb(0 0 0 / 12%);
}

.life-col {
  text-align: start;
  margin: auto;
}

.life-card-img {
  width: 70px;
  height: 70px;
  transition: all 0.5s ease-in-out;
}

.life-card-img:hover {
  @include transition2;
  -webkit-transform: scale(1.2);
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}

.life-card-title {
  font-size: 32px;
  line-height: 42px;
  font-weight: 700;
  line-height: 33px;
  letter-spacing: -0.02em;
  text-align: left;
  font-family: 'tt_commonsdemibold', sans-serif;
}

.life-card-text {
  text-align: left;
  font-size: 18px;
  color: #6a6a6a;
  line-height: 36px;
}

@media only screen and (min-width: 319px) and (max-width: 375px) {

  .life-card {
    height: 450px;
  }
}
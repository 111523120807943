.man-faq {
  width: 70%;
  margin: 0 auto;
  color: #171c3f;
}

.accordion-item {
  border-left: 0px solid !important;
  border-right: 0px solid !important;
}

.accordion-body {
  padding: 15px;
  font-weight: 400;
  text-align: left;
  color: #6a6a6a;
  font-family: "tt_commonsdemibold", sans-serif;
}

.faq-link-img {
  width: 40px;
  padding-right: 30px;
}

.faq-header-text{
  margin: 0;
  font-size: 1.1rem;
}

.accordion-button {
  border-top: none;
  cursor: pointer;
  font-weight: 900;
  font-size: 18px;
  border-bottom: thick double #171c3f;
  font-family: "tt_commonsmedium", sans-serif;
}